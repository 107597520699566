import * as Yup from "yup";

export const USER_INFORMATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required("Please enter first name"),
  lastName: Yup.string().required("Please enter last name"),
  email: Yup.string()
    .required("Please enter email")
    .email("Email is not valid"),
});

export const USER_ROLES_SCHEMA = Yup.object().shape({
  roles: Yup.array().test(
    "roles",
    "Please select at least one role",
    (value: any) => value && value.length > 0,
  ),
});
